import swal from "sweetalert2";
// import { format, parseISO, getHours } from "date-fns";
import moment from 'moment';

export function objectToOptions(obj) {
  return Object.keys(obj).map((key) => {
    return {
      value: key,
      text: obj[key],
      name: obj[key],
    };
  });
}
export function setModelValue(model, value) {
  Object.keys(model).forEach((key) => {
    if (typeof value[key] !== "undefined" && value[key] !== null) {
      // console.log(typeof value[key]);
      if (typeof value[key] === "object") {
        model[key].value = value[key];
      } else if (typeof value[key] === "number") {
        model[key].value = value[key] + "";
      } else {
        if (typeof value[key] === "boolean") {
          model[key].value = value[key] ? 1 : 0;
        } else if (String(value[key].trim()) !== "") {
          model[key].value = formatModelValue(model[key].type, value[key]);
        }
      }
    }
  });
  return model;
}
export function formatModelValue(type, value, format = null) {
  if (type === "price") {
    return "Rp. " + new Intl.NumberFormat("id-ID").format(value);
  } else if (type === "date") {
    console.log(format);
    // return moment(value).format(format)
  } else if (type === "time") {
    console.log(format);
    // return moment(value).format('HH:ss')
  } else {
    return value;
  }
}

export function saveProcess(model) {
  return new Promise(function(resolve, reject) {
    return swal
      .fire({
        title: "Anda yakin akan menyimpan data ini?",
        text: "Pastikan semua data telah benar",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Simpan",
      })
      .then((result) => {
        if (result.isDismissed) {
          reject(new Error("dismiss"));
        }
        model = serializeModel(model);
        return resolve(model);
      });
  });
}

export function serializeModel(model) {
  let data = {};
  Object.keys(model).forEach((key) => {
    if (typeof model[key].disabled === "undefined" || !model[key].disabled) {
      if (
        model[key].value !== null &&
        model[key].type === "attachment" &&
        model[key].type === "attachmentExcel"
      ) {
        data[key] = model[key].value;
      } else if (model[key].value !== null) {
        if (model[key].value !== null) {
          if (model[key].type === "price") {
            data[key] = cleanIdrFormat(model[key].value);
          } else if (model[key].type === "date") {
            if (model[key].value !== null && model[key].value.includes("-")) {
              data[key] = model[key].value;
            } else {
              data[key] = null;
            }
          } else if (typeof model[key].value === "object") {
            if (model[key].value.id !== undefined) {
              data[key] = model[key].value.id;
            } else {
              data[key] = model[key].value[0].id;
            }
          } else if (model[key].type === "radio") {
            if (model[key].label === "is_active" && data[key] !== undefined) {
              data[key] = model[key].value;
            }
            // else if (model[key].label === "is_active" && data[key] === undefined) {
            //   data[key] = parseInt(model[key].value);
            // }else
            data[key] = model[key].value;
          } else if (
            model[key].type === "phone" &&
            model[key].value.includes("-")
          ) {
            const inputPhoneNumber = model[key].value;
            const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, "");
            data[key] = `+${numericPhoneNumber}`;
          } else {
            if (typeof model[key].value === "boolean") {
              data[key] = model[key].value ? 1 : 0;
            } else if (model[key].useInteger) {
              data[key] = parseInt(model[key].value);
            } else if (typeof model[key].value === "number") {
              data[key] = model[key].value;
            } else if (typeof model[key].value === "object") {
              data[key] = model[key].value;
            } else if (model[key].value.trim() !== "") {
              data[key] = model[key].value;
            }
          }
        }
      }
    }
  });
  return data;
}

// export function convertDateTime(value, showSectionTIme = false) {
//   const date = parseISO(value);
//   const justDate = format(date, "dd-MM-yyyy, HH:mm");
//   const hours = getHours(date);
//   if (showSectionTIme) {
//     if (hours >= 0 && hours < 6) {
//       return justDate + " Dini Hari";
//     } else if (hours >= 6 && hours < 11) {
//       return justDate + " Pagi";
//     } else if (hours >= 11 && hours < 15) {
//       return justDate + " Siang";
//     } else if (hours >= 15 && hours < 18) {
//       return justDate + " Sore";
//     } else {
//       return justDate + " Malam";
//     }
//   } else {
//     return justDate + ' WIB'
//   }
// }

export function convertDateTime(value, showSectionTime = false) {
  const date = moment(value);
  const justDate = date.format('DD-MM-YYYY, HH:mm');
  const hours = date.hours();

  if (showSectionTime) {
    if (hours >= 0 && hours < 6) {
      return justDate + " Dini Hari";
    } else if (hours >= 6 && hours < 11) {
      return justDate + " Pagi";
    } else if (hours >= 11 && hours < 15) {
      return justDate + " Siang";
    } else if (hours >= 15 && hours < 18) {
      return justDate + " Sore";
    } else {
      return justDate + " Malam";
    }
  } else {
    return justDate + ' WIB';
  }
}

export function convertDate(value) {
  const date = moment(value);
  const justDate = date.format('DD-MM-YYYY');
  return justDate;
}

export function convertPrice(value) {
  // versi 1
  // return `Rp. ${value.toLocaleString("id-ID")},-`;

  // versi 2
  const formattedValue = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(value);

  const parts = formattedValue.split(" ");
  const rupiah = parts[0].replace("Rp", "Rp.");
  return `${rupiah}`;

  // return new Intl.NumberFormat("id-ID", {
  //   style: "currency",
  //   currency: "IDR",
  // }).format(value);
}

export function cleanIdrFormat(value) {
  return value !== null && value !== ""
    ? value
        .toString()
        .replace(/\./g, "")
        .toString()
        .replace(/,/g, ".")
    : 0;
}

export function serializeErrorMessage(error) {
  if (process.env.NODE_ENV !== "production") {
    console.error(error);
  }
  if (typeof error.response !== "undefined") {
    if (typeof error.response.data.errors !== "undefined") {
      let validationMessages = error.response.data.errors;
      if (typeof validationMessages === "string") {
        return validationMessages;
      } else if (Object.keys(validationMessages).length) {
        let errorMessages = "<ul>";
        for (let i = 0; i < Object.keys(validationMessages).length; i++) {
          errorMessages =
            errorMessages +
            "<li>" +
            validationMessages[Object.keys(validationMessages)[i]] +
            "</li>";
        }
        errorMessages = errorMessages + "</ul>";
        return errorMessages;
      } else {
        return error.response.data;
      }
    } else if (typeof error.response.data.message !== "undefined") {
      return error.response.data.message ===
        "The user credentials were incorrect." ||
        error.response.data.message === "Client authentication failed"
        ? "Kombinasi email/username & password salah"
        : error.response.data.message;
    } else if (typeof error.response.data !== "undefined") {
      return error.response.data;
    }
  } else if (typeof error === "string") {
    return error;
  } else {
    return "ada yang salah, silahkan coba kembali.";
  }
}

export function detectScreen() {
  let screenWidth = window.innerWidth;

  function onScreenResize() {
    screenWidth = window.innerWidth;
  }

  window.addEventListener("resize", onScreenResize);

  return screenWidth;
}
